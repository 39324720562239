import gsap from "gsap";

const componentAnimation = () => {
    
    const dataAnimations = document.querySelectorAll("[data-animation='default']");

    if (dataAnimations) {
        dataAnimations.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 0, y: 100});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 1, opacity: 1, y: 0});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }



    const dataAnimations2 = document.querySelectorAll("[data-animation='alternate']");

    if (dataAnimations2) {
        dataAnimations2.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 0, scale: 0.85});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 1.5, opacity: 1, scale: 1});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }


    const dataAnimations3 = document.querySelectorAll("[data-animation='left-to-right']");

    if (dataAnimations3) {
        dataAnimations3.forEach(function (dataAnimation) {
            gsap.set(dataAnimation, { opacity: 1, x: 0});
    
            const observeOptions = {
                root: null,
                threshold: 0.25,
                // rootMargin: "-150px"
            };

            const observeAni = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    } else {
                        gsap.to(dataAnimation, {duration: 3, x:"10vw"});
                        observeAni.unobserve(entry.target);
                    }
                });
            }, observeOptions);

            observeAni.observe(dataAnimation);
        });
    }
    


};

export default componentAnimation;

